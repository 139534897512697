import {transformQueryParams} from "~/utils/map";
import {MayBeReactive} from "~/utils/types";

type Filter = {
  category?: string;
};

export const useBusinesses = (filter: MayBeReactive<Filter> = {}) => {
  const {$api} = useNuxtApp();

  const {data, pending} = useAsyncData(
    "businesses",
    async () => {
      const queryParams = transformQueryParams(
        isRef(filter) ? filter.value : filter
      );

      const {
        data: {
          data: {businesses},
        },
      } = await $api.get(`/businesses${queryParams}`);

      return businesses;
    },
    {
      server: false,
      lazy: false,
      ...(isRef(filter) && {watch: [filter]}),
      default: () => [],
    }
  );

  const businesses = computed(() => {
    return data?.value || [];
  });

  return {
    businesses,
    loading: pending,
  };
};

export const useBusiness = (id: string) => {
  const {$api} = useNuxtApp();

  const {data, pending} = useAsyncData(`business-${id}`, async () => {
    const {
      data: {
        data: {business},
      },
    } = await $api.get(`/businesses/${id}`);
    return business;
  });

  const business = computed(() => {
    return data?.value || [];
  });

  return {
    business,
    loading: pending,
  };
};
